
.our-team-container{
    padding-top: 40px;
}
/* Ensure padding and borders are included in width and height */
.about-column {
    float: left;
    width: 33.3%;
    margin-bottom: 16px;
    padding: 0 8px;
    box-sizing: border-box; /* Include padding in width calculation */
    overflow: hidden; /* Prevent overflow issues */
}

.about-card {
    box-shadow: 0 4px 8px 0 white;
    margin: 8px;
    background-color: black;
    border-radius: 50px;
    /* Remove fixed height to adjust to content */
    height: auto;
    overflow: hidden; /* Prevent content overflow within card */
}

.about-section {
    padding-inline: 50px;
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden; /* Prevent overflow issues */
    padding-top: 50px;
}

@media (max-width: 1024px) {
    .about-column {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .about-column {
        width: 100%;
    }
    .our-team-container{
        margin-bottom: 15%;
    }
}

@media (max-width: 480px) {
}

.our-team-container {
    position: relative;
}

.about-container {
    padding: 0 16px;
    padding-top: 60px;
    background: var(--background);
    color: white;
    overflow: hidden; /* Prevent overflow issues */
}

.about-container::after,
.about-row::after {
    content: "";
    clear: both;
    display: table;
}

.about-title {
    color: grey;
}

.about-button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: black;
    background-color: #ffffff;
    text-align: center;
    cursor: pointer;
    width: 80%;
}

.about-button:hover {
    background-color: #555;
}

@media screen and (max-width: 650px) {
    .about-column {
        width: 100%;
        display: block;
    }
}

.contact-page-color {
  background: var(--background);
  color: var(--text-color);
}

.cta-section {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10%;
  padding-right: 10%;
  background: var(--background);
}

.cta-section h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #6c5ce7;
  padding: 15px 30px;
  color: var(--text-color);
  text-decoration: none;
  border-radius: 25px;
  font-size: 1.2em;
}

.cta-button-cover {
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.cta-note {
  margin-top: 20px;
  font-size: 1em;
  color: #bbb;
}
.cta-lower {
  font-size: 1.8em; /* Larger font size for emphasis */
  margin: 20px 0; /* Add margin for spacing */
  color: var(--text-color); /* Change text color */
  font-weight: bold; /* Make the text bold */
}

.cta-cards-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to the next line */
  justify-content: space-around; /* Distribute space between cards */
  margin-top: 20px;
  padding-inline: 10px;
}

.cta-card {
  background-color: transparent; /* Card background color */
  border: solid 1px white;
  color: var(--text-color);
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex: 1 0 20%; /* Adjust flex-grow, flex-shrink, and flex-basis */
  max-width: 200px; /* Set max width for the card */
  text-align: center; /* Center text inside cards */
  transition: transform 0.3s; /* Add a hover effect */
}

.cta-card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.cta-card h5 {
  font-size: 1.2em; /* Adjust font size for the card title */
  margin-bottom: 10px; /* Space below the title */
}

.cta-card p {
  font-size: 1em; /* Standard size for card descriptions */
}

.impact-section {
  padding: 50px;
  background-color: var(--background-color);
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}
.stats-container {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Distribute space between boxes */
  margin: 0 -10px; /* Negative margin to offset the padding on the boxes */
  margin-bottom: 50px;
}

.stat-box {
  background-color: transparent;
  border: solid 1px white;
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  margin: 10px;
}

.attention-section {
  padding: 50px;
  background-color: transparent;
  border: solid 1px white;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.attention-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.attention-card {
  /*background-color: #333;*/
  color: var(--text-color);
  /*padding: 20px;*/
  width: fit-content;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  flex: 1 0 20%;
  max-width: 150px;
}

.attention-card img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  width: fit-content;
}

.attention-card p {
  font-size: 1.1em;
  margin: 0;
}

/* FAQ Section */
.faq-section {
  padding: 50px;
  background-color: transparent;
  background: var(--background-section);
  /*color: white;*/
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-section h3 {
  font-size: 2em;
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  background-color: var(--overlay);
  color: var(--text-color);
  padding: 15px 20px;
  border: none;
  text-align: left;
  width: 100%;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: var(--overlay);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  background-color: var(--overlay);
  color: var(--text-color);
  padding: 0 20px;
  transition: max-height 0.3s ease;
}

.faq-answer.active {
  max-height: 200px;
  padding: 20px;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .stats-container {
    flex-direction: column;
  }

  .attention-cards {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .faq-question {
    font-size: 1em;
  }
}

.profiles-section {
  padding: 50px;

  /*background-color: #030303;
  background: radial-gradient(circle at 20% 30%, rgb(32, 12, 46), transparent 60%),
    radial-gradient(circle at 80% 70%, rgb(40, 15, 76), transparent 60%),
    radial-gradient(circle at 50% 50%, rgba(60, 100, 200, 0.8), transparent 70%);*/
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.profile-cards-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.profile-card {
  background-color: #000;
  border-radius: 15px;
  padding: 20px;
  width: 25%; /* This will adjust for larger screens */
  text-align: center;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s; /* Adds a hover effect */
}

.profile-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.profile-card img {
  width: 100px; /* Fixed width for consistency */
  height: 100px; /* Fixed height for consistency */
  border-radius: 50%; /* Ensure images are round */
  margin-bottom: 15px;
}

.profile-card p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.profiles-section h3 {
  margin-bottom: 30px;
  font-size: 1.8em;
}


@media screen and (max-width: 768px) {
  .cta-section h1 {
    font-size: 1.8em;
  }

  .cta-section p {
    font-size: 1em;
  }

  .cta-button {
    padding: 12px 24px;
    font-size: 1em;
  }

  .impact-section h2 {
    font-size: 1.5em;
  }

  .stats-container {
    flex-direction: column;
  }

  .profile-cards-container {
    flex-direction: column;
    gap: 20px;
  }

  .profile-card {
    width: 100%;
  }

  .stat-box h3 {
    font-size: 2em;
  }
}

@media screen and (max-width: 480px) {
  .cta-section h1 {
    font-size: 1.5em;
  }

  .cta-section p {
    font-size: 0.9em;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }

  .impact-section h2 {
	margin-left: 7%;    
font-size: 1.3em;
  }

  .stat-box h3 {
    font-size: 1.5em;
  }

  .profiles-section h3 {
    font-size: 1.5em;
  }
}

/* Modal styles */
.modal {
  display: block; /* Show the modal */
  position: fixed;
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with transparency */
}

.modal-content {
  position: relative;
  margin: 5% auto;
  padding: 20px;
  background-color: white;
  width: 100%;
  max-width: 1200px; /* Max width for large screens */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-y: hidden;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
}

.container .cta-section .header {
  margin-bottom: 40px;
}

.container .cta-sub-section {
  margin-top: 20px;
  margin-bottom: 30px;
}



/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 95%;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  margin-bottom: 20px;
}

.modal-body ul {
  list-style: none;
  padding-left: 0;
}

.modal-body ul li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.modal-body p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

/* Form */
.modal-form .form-group {
  margin-bottom: 15px;
}

.modal-form label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.modal-form input,
.modal-form select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-form input:focus,
.modal-form select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Buttons */
.open-modal-btn,
.close-modal-btn,
.submit-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.open-modal-btn {
  background-color: #007bff;
  color: white;
}

.close-modal-btn {
  background-color: #6c757d;
  color: white;
}

.submit-btn {
  background-color: #28a745;
  color: white;
}

.open-modal-btn:hover,
.close-modal-btn:hover,
.submit-btn:hover {
  opacity: 0.9;
}

/* Modal Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

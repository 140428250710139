/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background: var(--background);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar .container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

nav.navbar a.navbar-brand {
  min-width: 100px;
  width: 15%; /* Adjust this value to keep logo size proportional */
  text-align: center;
}

.left-nav,
.right-nav {
  display: flex;
  align-items: center;
}

.left-nav .navbar-link,
.right-nav .navbar-link {
  margin: 0 20px; /* Equal margin for both sides */
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 5px 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
  margin-top: 30px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.navbar-link {
  position: relative;
  color: white;
  padding: 8px 16px;
}

/* ---------------Navbar Dropdown------------- */
.nav-item.dropdown {
  position: relative;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #533d66;
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
}

.dropdown-content .row {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-content a {
  color: #fff;
  padding: 8px 12px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #241f29;
}

.scan-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  height: 60px;
  width: 130px;
  border: none;
  border-radius: 8px;
  margin-left: 48.9%; /* Slight margin to create space from input */
  position: relative;
  bottom: 4.1rem;
  cursor: pointer;
}

.scan-button svg {
  margin-right: 10px; /* Space between SVG icon and text */
}

.scan-button:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .scan-button{
    margin-left: 65%;
  }
}


/************ Banner Css ************/
.banner {
  margin-top: 0;
  background: var(--background);
}
@media screen and (max-width: 768px) {
  .banner {
    padding: 130px 0 100px 0;
  }
}
@media screen and (min-width: 769px) {
  .banner {
    padding: 200px 0 100px 0;
  }
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

/* .banner img {
    animation: updown 3s linear infinite;
  } */
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

/* sarchhhhhhhhhh */

/* From Uiverse.io by Amankrah */ 
.galaxy {
  height: 800px;
  width: 800px;
  background-image: radial-gradient(#ffffff 1px, transparent 1px),
    radial-gradient(#ffffff 1px, transparent 1px);
  background-size: 50px 50px;
  background-position:
    0 0,
    25px 25px;
  position: absolute;
  z-index: -1;
  animation: twinkle 5s infinite;
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.stardust,
.cosmic-ring,
.starfield,
.nebula {
  max-height: 70px;
  max-width: 314px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  border-radius: 12px;
  filter: blur(3px);
}

.input {
  background-color: #000000;
  border: none;
  width: 500px;
  height: 70px;
  border-radius: 10px;
  color: #ffffff;
  padding-inline: 24px;
  font-size: 20px;
}

#search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 33%;
  margin-top: 6%;
}

.input::placeholder {
  color: #b8b6b6;
}

.input:focus {
  outline: none;
}

#main:focus-within > #input-mask {
  display: none;
}

#input-mask {
  pointer-events: none;
  width: 100px;
  height: 20px;
  position: absolute;
  background: linear-gradient(90deg, transparent, #05071b);
  top: 18px;
  left: 70px;
}

#cosmic-glow {
  pointer-events: none;
  width: 60px;
  height: 40px;
  position: absolute;
  background: #4d6dff;
  top: 17px;
  left: 5px;
  filter: blur(20px);
  opacity: 0.8;
  transition: all 2s;
}

#main:hover > #cosmic-glow {
  opacity: 0;
}

.stardust {
  max-height: 63px;
  max-width: 307px;
  border-radius: 10px;
  filter: blur(2px);
}

.stardust::before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(83deg);
  position: absolute;
  width: 600px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  filter: brightness(1.4);
  background-image: conic-gradient(
    rgba(255, 255, 255, 0) 0%,
    #4d6dff,
    rgba(0, 0, 0, 0) 8%,
    rgba(0, 0, 0, 0) 50%,
    #6e8cff,
    rgba(0, 0, 0, 0) 58%
  );
  transition: all 2s;
}

.cosmic-ring {
  max-height: 72px;
  max-width: 506px;
  border-radius: 11px;
  filter: blur(0.5px);
}

.cosmic-ring::before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(70deg);
  position: absolute;
  width: 600px;
  height: 600px;
  filter: brightness(1.3);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    #05071b,
    #4d6dff 5%,
    #05071b 14%,
    #05071b 50%,
    #6e8cff 60%,
    #05071b 64%
  );
  transition: all 2s;
}

.starfield {
  max-height: 65px;
  max-width: 312px;
}

.starfield::before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(82deg);
  position: absolute;
  width: 600px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #1c2452,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 50%,
    #2a3875,
    rgba(0, 0, 0, 0) 60%
  );
  transition: all 2s;
}

#search-container:hover > .starfield::before {
  transform: translate(-50%, -50%) rotate(-98deg);
}

#search-container:hover > .nebula::before {
  transform: translate(-50%, -50%) rotate(-120deg);
}

#search-container:hover > .stardust::before {
  transform: translate(-50%, -50%) rotate(-97deg);
}

#search-container:hover > .cosmic-ring::before {
  transform: translate(-50%, -50%) rotate(-110deg);
}

#search-container:focus-within > .starfield::before {
  transform: translate(-50%, -50%) rotate(442deg);
  transition: all 4s;
}

#search-container:focus-within > .nebula::before {
  transform: translate(-50%, -50%) rotate(420deg);
  transition: all 4s;
}

#search-container:focus-within > .stardust::before {
  transform: translate(-50%, -50%) rotate(443deg);
  transition: all 4s;
}

#search-container:focus-within > .cosmic-ring::before {
  transform: translate(-50%, -50%) rotate(430deg);
  transition: all 4s;
}

.nebula {
  overflow: hidden;
  filter: blur(30px);
  opacity: 0.4;
  max-height: 130px;
  max-width: 354px;
}

.nebula:before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(60deg);
  position: absolute;
  width: 999px;
  height: 999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    #000,
    #4d6dff 5%,
    #000 38%,
    #000 50%,
    #6e8cff 60%,
    #000 87%
  );
  transition: all 2s;
}

#wormhole-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  max-height: 40px;
  max-width: 38px;
  height: 100%;
  width: 100%;
  isolation: isolate;
  overflow: hidden;
  border-radius: 10px;
  background: linear-gradient(180deg, #1c2452, #05071b, #2a3875);
  border: 1px solid transparent;
}

.wormhole-border {
  height: 42px;
  width: 40px;
  position: absolute;
  overflow: hidden;
  top: 7px;
  right: 7px;
  border-radius: 10px;
}

.wormhole-border::before {
  content: "";
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  position: absolute;
  width: 600px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  filter: brightness(1.35);
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #4d6dff,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    #6e8cff,
    rgba(0, 0, 0, 0) 100%
  );
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .galaxy {
    height: 400px; /* Adjust height for smaller screens */
    width: 400px; /* Adjust width for smaller screens */
  }

  .input {
    width: 100%; /* Full width for input */
    font-size: 16px; /* Adjust font size */
    padding: 12px; /* Adjust padding */
  }

  #search-container {
    margin-right: 0; /* Center search container */
    margin-top: 10%; /* Adjust top margin */
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Stretch items to full width */
  }

  #wormhole-icon {
    max-height: 30px; /* Adjust icon size */
    max-width: 28px; /* Adjust icon size */
  }

  .stardust, .cosmic-ring, .starfield, .nebula {
    max-height: 50px; /* Adjust max height */
    max-width: 100%; /* Make them responsive */
  }
}


/* Services CSS */

/* Container */
.vapt-services-container {
  background-color: #443256;
  text-align: center;
}

/* Heading */
.vapt-services-heading {
  font-size: 36px;
  color: white;
  margin-bottom: 16px;
  font-weight: bold;
}

.vapt-services-subheading {
  font-size: 18px;
  color: #c5b5d2;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Services Grid */
.vapt-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

/* Individual Card */
.vapt-service-card {
  background-color: white;
  border-radius: 12px;
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vapt-service-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
}

.vapt-service-image {
  max-width: 80px;
  margin-bottom: 16px;
  transition: transform 0.3s ease;
}

.vapt-service-card:hover .vapt-service-image {
  transform: scale(1.1);
}

.vapt-service-title {
  font-size: 20px;
  color: #443256;
  font-weight: 600;
  margin-bottom: 10px;
}

.vapt-service-description {
  font-size: 16px;
  color: #777;
  line-height: 1.4;
}

/* Start Button */
.vapt-start-button {
  display: inline-flex;
  align-items: center;
  background-color: #6a50ad;
  color: white;
  font-size: 18px;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vapt-start-button:hover {
  background-color: #563a9e;
}

.vapt-arrow {
  margin-left: 10px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .vapt-services-container{
    padding: 1px 10%;
  }
  .vapt-services-heading {
    margin-top: 10%;
    font-size: 28px;
  }

  .vapt-services-subheading {
    font-size: 16px;
  }

  .vapt-service-title {
    font-size: 18px;
  }

  .vapt-service-description {
    font-size: 14px;
  }
}


/* Certifications of team */

/* Certifications Section */
.certifications-container {
  background-color: #443256;
  text-align: center;
  color: white;
}

.certifications-heading {
  font-size: 36px;
  margin-bottom: 16px;
  font-weight: bold;
}

.certifications-subheading {
  font-size: 18px;
  color: #c5b5d2;
  margin-bottom: 40px;
}

/* Grid of Certifications */
.certifications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 40px;
  justify-items: center;
  align-items: center;
}

.certification-card {
  background-color: rgba(255, 255, 255, 0.1); /* Transparent background */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #fff;
  width: 180px;  /* Fixed width */
  height: 220px; /* Fixed height */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Hover effect */
.certification-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.certification-image {
  max-width: 80px;
  margin-bottom: 10px;
}

.certification-title {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  .certifications-container{
    padding: 5% 8%;
  }

  .certifications-heading {
    font-size: 28px;
    margin-top: 10%;
  }

  .certifications-subheading {
    font-size: 16px;
  }

  .certification-title {
    font-size: 12px;
  }

  .certification-card {
    width: 150px;  /* Adjusted for smaller screens */
    height: 200px;
  }
}


/* PRICING */

.pricing-section {
  background-color: #3f2a56;
  color: #fff;
  padding: 2rem;
  text-align: center;
}

.pricing-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  padding: 1.5rem;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.old-price {
  text-decoration: line-through;
  color: #ff6f61;
}

.new-price {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.start-now-btn {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.start-now-btn:hover {
  background-color: #008ecc;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.pricing-card ul li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
    
  }

  .pricing-card {
    width: 100%;
    max-width: 350px;
  }
}

/* PROCESS */
.process-section {
  background-color: #3f2a56;
  text-align: center;
  color: white;
}

.process-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.process-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #b5c9dc;
}

.process-steps {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.process-step {
  background-color: white;
  color: #333;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-basis: calc(25% - 1rem);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.process-step:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
}

.process-step img {
  max-width: 100px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.process-step h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.process-step p {
  font-size: 1rem;
  color: #666;
}

/* Mobile responsive design */
@media (max-width: 768px) {
  .process-section{
    padding: 10% 10%;
  }
  .process-steps {
    flex-direction: column;
  }

  .process-step {
    flex-basis: 100%;
    margin-bottom: 1.5rem;
  }
}






/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
  background-color: var(--background-color);
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  box-shadow: 0 4px 8px 0 white;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background: var(--background-section);
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background: var(--background);
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer .logo-dimension {
  width: 30%;
  padding: 20px;
}
.footer .logo {
  width: 50%;
  padding: 20px;
}

/*
.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
*/

/* -----------------Contact-------------------- */
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contact-background {
  display: flex;
  min-height: 50vh;
  background: var(--background-color);
}

.left-content-contact {
  max-width: 45%;
  padding: 35px;
}

.contact-container {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
  margin-right: 4.25%;
}

.contact-screen {
  position: relative;
  background: #3e3e3e;
  border-radius: 15px;
}

.contact-screen:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
  z-index: -1;
}

.contact-screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.contact-screen-header-left {
  margin-right: auto;
}

.contact-screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.contact-screen-header-button.close {
  background: #ed1c6f;
}

.contact-screen-header-button.maximize {
  background: #e8e925;
}

.contact-screen-header-button.minimize {
  background: #74c54f;
}

.contact-screen-header-right {
  display: flex;
}

.contact-screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.contact-screen-body {
  display: flex;
}

.contact-screen-body-item {
  flex: 1;
  padding: 50px;
}

.contact-screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.contact-app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  /* color: #ea1d6f; */
  color: white;
  font-size: 26px;
}

.contact-app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  /* background: #ea1d6f; */
  background: white;
}

.contact-app-contact {
  margin-top: auto;
  font-size: 12px;
  color: #888;
}

.contact-app-form-group {
  margin-bottom: 15px;
}

.contact-message {
  margin-top: 40px;
}

.contact-buttons {
  margin-bottom: 0;
  text-align: right;
}

.contact-app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color .2s;
}

.contact-app-form-control::placeholder {
  color: #666;
}

.contact-app-form-control:focus {
  border-bottom-color: #ddd;
}

.contact-app-form-button {
  background: none;
  border: none;
  /* color: #ea1d6f; */
  color: white;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.contact-app-form-button:hover {
  color: #b9134f;
}

.contact-credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffa4bd;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.contact-credits-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.contact-dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (min-width: 990px) {
  .certifications-container {
    padding: 60px 280px;
  }
  .vapt-services-container {
    padding: 60px 190px;
  }
  
  .process-section {
    padding: 60px 200px;
  }
}
@media screen and (min-width: 750px) and (max-width: 989px) {

}
@media screen and (min-width: 491px) and (max-width: 749px) {
  .contact-image {
    display: none;
  }
  .contact-container {
    width: 100vw;
    position: relative;
    right: 20px;
    /* background: red; */
  }
  .banner h1 {
    font-size: 8vw; /* Adjust font size for smaller screens */
  }
  .banner p {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }
  .banner button {
    margin-top: 0vh; /* Adjust margin for smaller screens */
  }
  .vertical-center {
    text-align: center;
  }
  .contact-screen-body {
    flex-direction: column;
  }

  .contact-screen-body-item.left {
    margin-bottom: 30px;
  }

  .contact-app-title {
    flex-direction: row;
  }

  .contact-app-title span {
    margin-right: 12px;
  }

  .contact-app-title:after {
    display: none;
  }
  .contact-image {
    display: none;
  }
  .contact-container {
    width: 100vw;
  }
  nav.navbar a.navbar-brand {
    width: 40%;
  }
}
@media screen and (max-width: 490px) {
  .contact-image {
    display: none;
  }
  .contact-container {
    width: 100vw;
    position: relative;
    right: 20px;
    /* background: red; */
  }
  .banner h1 {
    font-size: 8vw; /* Adjust font size for smaller screens */
  }
  .banner p {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }
  .banner button {
    margin-top: 0vh; /* Adjust margin for smaller screens */
  }
  .vertical-center {
    text-align: center;
  }
  .contact-screen-body {
    padding: 40px;
  }
  .contact-screen-body-item {
    padding: 0;
  }
  .contact-image {
    display: none;
  }
}
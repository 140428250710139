@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

/* Base styles */
.login-page-box {
    box-sizing: border-box;
}

/* Body styles */
.login-page-body {
    background-color: #0d0e13; /* Base dark background */
    background: radial-gradient(circle at 25% 30%, rgba(34, 16, 70, 0.8), transparent 60%), /* Top-left subtle glow */
                radial-gradient(circle at 75% 70%, rgba(72, 23, 141, 0.6), transparent 60%), /* Bottom-right glow */
                radial-gradient(circle at 50% 50%, rgba(60, 0, 120, 0.5), transparent 70%); /* Central purple glow */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    margin: -20px 0 50px;
    overflow-y: hidden;
}
.login-page-body::-webkit-scrollbar {
    display: none;
}

/* Typography */
.login-page-h1 {
    font-weight: bold;
    margin: 0;
}

.login-page-h2 {
    text-align: center;
}

.login-page-p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

.login-page-span {
    font-size: 12px;
}

.login-page-a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

/* Button styles */
.login-page-button {
    border-radius: 20px;
    border: 1px solid #FF4B2B;
    background-color: #FF4B2B;
    color: #FFFFFF;
    margin-top: 25%;
    margin-left: 28%;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

.login-page-button-active {
    transform: scale(0.95);
}

.login-page-button-focus {
    outline: none;
}

.login-page-button-ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

/* Form styles */
.login-page-form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.login-page-input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 10px 10%;
    margin-top: 10%;
    width: 80%;
}

/* Container styles */
.login-page-container {
    background-color: #4a4a4a; /* Slightly lighter background for the form area */
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(255, 255, 255, 0.25),
        0 10px 10px rgba(255, 255, 255, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

/* Adjust container width for smaller screens */
@media (max-width: 768px) {
    .login-page-container {
        width: 90%;
    }
}

.login-page-form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.login-page-sign-in-container,
.login-page-sign-up-container {
    width: 50%;
    z-index: 2;
}

.login-page-container-right-panel-active .login-page-sign-in-container,
.login-page-container-right-panel-active .login-page-sign-up-container {
    transform: translateX(100%);
}

@keyframes login-page-show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

/* Overlay styles */
.login-page-overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.login-page-container-right-panel-active .login-page-overlay-container {
    transform: translateX(-100%);
}

.login-page-overlay {
    background: linear-gradient(to right, #FF4B2B, #FF416C);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login-page-container-right-panel-active .login-page-overlay {
    transform: translateX(50%);
}

.login-page-overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login-page-overlay-left {
    transform: translateX(-20%);
}

.login-page-container-right-panel-active .login-page-overlay-left {
    transform: translateX(0);
}

.login-page-overlay-right {
    right: 0;
    transform: translateX(0);
}

.login-page-container-right-panel-active .login-page-overlay-right {
    transform: translateX(20%);
}

/* Social container */
.login-page-social-container {
    margin: 20px 0;
}

.login-page-social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

/* Footer styles */
.login-page-footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

.login-page-footer-p {
    margin: 10px 0;
}

.login-page-footer-i {
    color: red;
}

.login-page-footer-a {
    color: #3c97bf;
    text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .login-page-button {
        margin-top: 50%;
        margin-left: 15%;
        padding: 10px 35px;
        font-size: 10px;
    }

    .login-page-input {
        margin: 10px 5%;
        width: 90%;
        margin-top: 30%;
    }

    .login-page-h1, .login-page-h2 {
        font-size: 18px;
    }

    .login-page-p {
        font-size: 12px;
    }

    .login-page-footer {
        font-size: 12px;
    }
}

/*******************Global Variable Space ***************/
:root {
  --primary-color: rgb(32, 12, 46);
  --secondary-color: rgb(94 54 151);
  --text-color: #ffffff;
  --color:#ffffff;
  --success-color: green;
  --error-color: red;
  --font-family: 'Centra', sans-serif;
  --background: linear-gradient(180deg, #1a181c, #533d66);
  --background-section: linear-gradient(180deg, #000, #533d66);
  --background-color:  #000;
  --designation: rgb(181, 156, 188);
  --overlay: rgba(255, 255, 255, 0.110);
  --box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  --time: fill 50s infinite;
}

/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background: var(--background) !important;
  color: var(--color) !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: var(--success-color);
}

p.danger {
	color: var(--error-color);
}


.recon-result-full {
  background: var(--background);
}

.host-light-scan {
  color: #ffffff;
  padding: 150px 100px;
  border-radius: 10px;
  font-family: 'Arial', sans-serif;
}

.scan-header {
  text-align: left;
  margin-bottom: 15px;
}

.scan-header h2 {
  font-size: 1.5em;
  margin: 0;
}

.scan-header p {
  font-size: 0.9em;
  color: #b5b5b5;
}

.scan-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.progress-bar-container {
  width: 90%;
  background-color: #1e1e2e;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  background-color: #22bb33;
  text-align: right;
  padding-right: 10px;
  color: #ffffff;
  line-height: 30px;
  height: 30px;
  transition: width 4.5s ease;
}

.progress-status {
  margin-top: 1rem;
  margin-left: 15px;
  color: #ff9900;
  font-weight: bold;
}

.scan-results {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.result-card {
  width: 18%;
  background-color: #1e2a38;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.result-card p {
  margin: 5px 0;
}

.critical {
  background-color: #a92323;
  cursor: pointer;
}

.high-risk {
  background-color: #d32f2f;
  cursor: pointer;
}

.medium-risk {
  background-color: #ff8800;
  cursor: pointer;
}

.low-risk {
  background-color: #2e7d32;
  cursor: pointer;
}

.informational {
  background-color: #00d0ff;
  cursor: pointer;
}

/* General container */
.scan-results-container {
  background-color: #282c34;
  color: #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  max-width: 100%;
  margin: 20px auto;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

/* Each item */
.scan-item {
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.scan-item:hover {
  background-color: #3d4150;
}

.recon-result .container {
  font-size: 16px;
  font-weight: 200;
  color: #ffffff;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

.heading span {
  display: flex;
  align-items: center;
}

.heading svg {
  color: #ffffff;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.side-by-side-recon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.container-recon {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 3rem;
}

.section {
  width: 90%;
  margin-left: 5%;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.1); 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  cursor: pointer;
}

.content {
  display: none;
  font-size: 14px;
  padding-top: 10px;
  color: #c5c6c7;
  transition: all 0.4s ease-in-out;
}

.content.open {
  display: block;
}

/* List items in the content */
ul {
  margin-top: 10px;
  padding-left: 20px;
}

li {
  list-style: none;
  padding: 5px 0;
}

.yellow {
  color: #ffc107;
}

.green {
  color: #28a745;
}

.risk, .recommendation {
  margin-top: 10px;
  font-size: 13px;
  color: #c5c6c7;
  background-color: #2a2e3b;
  padding: 8px;
  border-radius: 5px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .host-light-scan {
    padding: 90px 20px;
  }

  .scan-results {
    flex-direction: column;
    align-items: center;
  }

  .result-card {
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .scan-results-container {
    padding: 15px;
  }

  .section {
    width: 90%;
    padding: 1rem;
  }

  .heading {
    font-size: 1rem;
  }

  .scan-header h2 {
    font-size: 1.25em;
  }

  .scan-header p {
    font-size: 0.8em;
  }

  .content {
    font-size: 13px;
  }
}

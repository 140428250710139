.appSec-container {
    padding: 20px;
    /* background-color: #f8f9fa; */
    background: var(--background);
    margin-top: 14%;
}

.appSec-header {
    text-align: center;
    margin-bottom: 40px;
}

.appSec-title {
    font-size: 2.5rem;
    color: white;
    margin-bottom: 10px;
}

.appSec-subtitle {
    font-size: 1.2rem;
    color: white;
}

.appSec-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.appSec-overview {
    flex: 1;
    margin-right: 20px;
}

.appSec-overview-title {
    font-size: 1.75rem;
    color: white;
    margin-bottom: 15px;
    margin-left: 20%;
}

.appSec-overview-text {
    font-size: 1rem;
    color: white;
    line-height: 1.6;
}

.appSec-image img {
    max-width: 100%;
    height: auto;
}

.appsecButton {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
}

.appsecButton svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.appsecButton:hover svg {
    margin-left: 25px;
}

.appSec-methodology {
    margin-bottom: 40px;
}

.appSec-methodology-title {
    font-size: 1.75rem;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.appSec-methodology-content img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

.appSec-checklist {
    margin-bottom: 40px;
}

.appSec-checklist-title {
    font-size: 1.5rem;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.appSec-checklist-items {
    display: flex;
    justify-content: center;
}

.appSec-checklist-column {
    list-style: none;
    padding: 0;
    margin: 0 20px;
}

.appSec-checklist-column li {
    font-size: 1rem;
    color: white;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.appSec-checklist-column li:before {
    content: "◉";
    color: white;
    margin-right: 10px;
}

.appSec-footer {
    text-align: center;
    margin-top: 40px;
}

.appSec-methodology-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.appSec-methodology-content {
    flex: 1;
    margin-right: 20px;
}

.appSec-methodology-text {
    flex: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .appSec-overview{
        text-align: center;
    }
    .appSec-overview-title{
        margin-left: 10px;
    }
}
/* General Container */
.dashboard-portal-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #0d0e13;
    background: radial-gradient(circle at 20% 30%, rgba(35, 12, 52, 1), transparent 60%),
                radial-gradient(circle at 80% 70%, rgba(50, 15, 100, 1), transparent 60%),
                radial-gradient(circle at 50% 50%, rgba(60, 100, 200, 0.8), transparent 70%);
}

/* Header */
.dashboard-portal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Make header items wrap on small screens */
}

.dashboard-portal-header-controls button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

/* Stats */
.dashboard-portal-stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Make stats stack on small screens */
    margin-top: 20px;
}

.dashboard-portal-stat {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    color: white;
    min-width: 180px; /* Ensure stats have a minimum width */
}

.dashboard-portal-stat:last-child {
    margin-right: 0;
}

.dashboard-portal-stat.critical {
    background-color: #ff4c4c;
}

.dashboard-portal-stat.high {
    background-color: #f39c12;
}

.dashboard-portal-stat.medium {
    background-color: #f1c40f;
}

.dashboard-portal-stat.low {
    background-color: #2ecc71;
}

.dashboard-portal-stat.info {
    background-color: #3498db;
}

.dashboard-portal-stat-number {
    font-size: 24px;
    font-weight: bold;
    display: block;
}

.dashboard-portal-stat-label {
    font-size: 14px;
    margin-top: 5px;
    display: block;
}

/* Charts */
.dashboard-portal-charts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Make charts stack on small screens */
    margin-top: 20px;
}

.dashboard-portal-chart {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    min-width: 180px; /* Ensure charts have a minimum width */
}

.dashboard-portal-chart:last-child {
    margin-right: 0;
}

.dashboard-portal-piechart {
    height: 200px;
    width: 200px;
    background-color: #ddd;
    border-radius: 50%;
    margin: 0 auto;
}

/* Vulnerabilities */
.dashboard-portal-vulnerabilities {
    margin-top: 40px;
}

.dashboard-portal-linechart {
    height: 300px;
    background-color: #eee;
    border-radius: 5px;
}

/* Top Vulnerabilities */
.dashboard-portal-top-vulnerabilities {
    margin-top: 40px;
}

.dashboard-portal-vulnerability-list {
    list-style: none;
    padding: 0;
}

.dashboard-portal-vulnerability-list li {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    color: white;
    background-color: #ff4c4c;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .dashboard-portal-stats, 
    .dashboard-portal-charts {
        flex-direction: column; /* Stack stats and charts vertically */
    }

    .dashboard-portal-stat, 
    .dashboard-portal-chart {
        margin-right: 0; /* Remove margin on smaller screens */
    }
}

@media (max-width: 768px) {
    .dashboard-portal-header {
        flex-direction: column; /* Stack header items vertically */
        text-align: center;
    }

    .dashboard-portal-header-controls button {
        margin: 10px 0; /* Space out buttons vertically */
    }
}

@media (max-width: 480px) {
    .dashboard-portal-stat-number {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .dashboard-portal-stat-label {
        font-size: 12px; /* Adjust font size for smaller screens */
    }

    .dashboard-portal-piechart {
        height: 150px;
        width: 150px; /* Adjust chart size for smaller screens */
    }

    .dashboard-portal-linechart {
        height: 250px; /* Adjust chart size for smaller screens */
    }
}

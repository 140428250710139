/* General styling */
.scanner-container {
  background-color: #1e1e2e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  border-radius: 15px;
  color: white;
}

.scanner-left {
  max-width: 50%;
  padding: 130px 30px;
}

.scanner-wolf-logo {
  width: 80px;
  height: auto;
}

.scanner-title {
  font-size: 36px;
  margin: 20px 0;
}

.scanner-highlight {
  color: #679aff;
}

.scanner-description {
  font-size: 16px;
  line-height: 1.5;
}

.scanner-right {
  background-color: #E4E4F5;
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  margin-right: 7%;
}

.scanner-input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.scanner-input-label {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.scanner-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #98A3D4;
  font-size: 16px;
}

.scanner-button {
  background-color: #45cbf8;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.scanner-button:hover {
  background-color: #0077ff;
}

.features-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for the grid layout */
  gap: 40px;
  padding: 50px;
  background-color: #1e1e2e;
  color: white;
}

.feature-item {
  display: flex; 
  align-items: center; 
  justify-content: space-between; /* Align items with space between */
  gap: 20px; 
}

.feature-icon {
  width: 80px; 
  height: auto; 
}

.blue-icon {
  width: 80px; 
  height: 80px; 
  background-color: #4a90e2; 
  border-radius: 50%; 
}

.target-icon {
  width: 80px; 
  height: 80px; 
  background: url('/src/assets/tools/wolf.webp') no-repeat center; 
  background-size: contain; 
}

.feature-content h3 {
  font-size: 20px; 
  margin-bottom: 10px; 
}

.feature-content p {
  font-size: 16px; 
  line-height: 1.6; 
}

.feature-content a {
  color: #ff6f61; 
  text-decoration: none; 
}

.feature-content a:hover {
  text-decoration: underline; 
}

/* Responsive design */
@media (max-width: 768px) {
  .scanner-container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    padding: 20px;
  }

  .scanner-left, .scanner-right {
    max-width: 100%; /* Full width for both sides */
    /* padding: 20px; */
    padding-top: 80px;
    margin: 0; /* Remove side margin */
  }
  .scanner-right{
    width: 90%;
    padding-top: 8%;
    position: relative;
    bottom: 5rem;
  }

  .scanner-title {
    font-size: 28px; /* Adjust title size for readability */
  }

  .scanner-description, .scanner-input-label, .scanner-input {
    font-size: 14px; /* Smaller font for mobile */
  }

  .features-container {
    grid-template-columns: 1fr; /* Single column layout on small screens */
    gap: 20px;
    padding: 20px;
  }

  .feature-item {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: flex-start;
    text-align: left;
  }

  .feature-content h3 {
    font-size: 18px; /* Adjust header size */
  }

  .feature-content p {
    font-size: 14px; /* Adjust text size */
  }
}

/* Add custom styles for zigzag effect */
.feature-item:nth-child(odd) {
  flex-direction: row; /* Left wolf on odd blocks */
}

.feature-item:nth-child(even) {
  flex-direction: row-reverse; /* Right wolf on even blocks */
}
